export var headers = {
	"Project-Id-Version": "open-xchange-appsuite-phishingchecker",
	"Report-Msgid-Bugs-To": "",
	"POT-Creation-Date": "",
	"PO-Revision-Date": "2020-07-31 19:32-0600",
	"Last-Translator": "timothy <timothy@workshop-chapina.com>",
	Language: "en_US",
	"Language-Team": "English <kde-i18n-doc@kde.org>",
	"Content-Type": "text/plain; charset=UTF-8",
	"Content-Transfer-Encoding": "8bit",
	"Plural-Forms": "nplurals=2; plural=(n != 1);",
	"MIME-Version": "1.0",
	"X-Generator": "Lokalize 20.04.1"
};
export default {
	headers: headers,
	"\u0000Cancel\u0001": [
	"Cancel"
]
};
